import { usePunditUserContext } from "@circle-react/contexts";

export const useCrmTrueLocationFieldAndSearchEnabled = (): boolean => {
  const { currentCommunitySettings } = usePunditUserContext();

  return Boolean(
    currentCommunitySettings?.crm_true_location_field_and_search_enabled,
  );
};

export const useCrmLocationFieldTypeOnCustomProfileFieldsEnabled =
  (): boolean => {
    const { currentCommunitySettings } = usePunditUserContext();

    return Boolean(
      currentCommunitySettings?.crm_location_field_type_on_custom_profile_fields_enabled,
    );
  };
