import type { RenderFilter } from "./types";
import { useCrmTrueLocationFieldAndSearchEnabled } from "./useCrmLocationEnabled";

export const useShouldShowGeolocationFilter = (filters: RenderFilter[]) => {
  const isCrmTrueLocationFieldAndSearchEnabled =
    useCrmTrueLocationFieldAndSearchEnabled();

  if (isCrmTrueLocationFieldAndSearchEnabled) {
    return filters;
  }

  return filters.filter(filter => filter.name !== "geo_location");
};
