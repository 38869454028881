import { t } from "@/i18n-js/instance";
import { useAccessGroups } from "@circle-react/hooks/accessRegistry/useAccessGroups";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";
import { Filter } from "@circle-react-shared/FilterV3";
import { Loader } from "@circle-react-uikit/Loader";
import { ArrayFilterComparator } from "../../Filter/FilterTypes/ArrayFilterComparator/ArrayFilterComparator";
import {
  type ArrayComparatorParam,
  handleArrayComparatorApply,
} from "../../Filter/FilterTypes/ArrayFilterComparator/arrayComparators";
import { useArrayComparatorSelectedItem } from "../../Filter/FilterTypes/ArrayFilterComparator/useArrayComparatorSelectedItem";

const localeNamespace = "members_directory.header.search.filters.access_groups";

interface AccessGroupsFilterProps {
  name: string;
  isInitialOpen?: boolean;
  onApply?: (value: ArrayComparatorParam | null) => void;
  param: ArrayComparatorParam;
}

export const AccessGroupsFilter = ({
  name,
  isInitialOpen = false,
  onApply,
  param,
}: AccessGroupsFilterProps) => {
  const { data: accessGroups, isLoading: isLoadingAccessGroups } =
    useAccessGroups();

  const options =
    accessGroups?.map((accessGroup: AccessGroupType) => ({
      label: accessGroup.name,
      value: `${accessGroup.id}`,
    })) || [];

  const renderSelectedValue = useArrayComparatorSelectedItem(options);

  return (
    <Filter onApply={param => handleArrayComparatorApply(param, onApply)}>
      <Filter.Chip renderSelectedValue={() => renderSelectedValue(param)}>
        {t([localeNamespace, "singular_title"])}
      </Filter.Chip>

      <Filter.Popover
        title={t([localeNamespace, "title"])}
        isInitialOpen={isInitialOpen}
      >
        {isLoadingAccessGroups ? (
          <Loader />
        ) : (
          <ArrayFilterComparator name={name} options={options} param={param} />
        )}
      </Filter.Popover>
    </Filter>
  );
};
