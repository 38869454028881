import { useMemo } from "react";
import { useSegments } from "@circle-workflows/hooks/useSegments";
import { t } from "@/i18n-js/instance";
import { SingleSelectFilter } from "@circle-react-shared/Filter/FilterTypes/SingleSelectFilter/SingleSelectFilterV2";
import { Filter } from "@circle-react-shared/FilterV3";
import { Loader } from "@circle-react-uikit/Loader";
import { renderSingleSelectFilterSelectedText } from "../../../Filter/FilterTypes/SingleSelectFilter/SelectedTextV2";
import type { BaseFilter } from "../../types";
import { SegmentTooltip } from "./SegmentTooltip";

const localeNamespace = "members_directory.header.search.filters.segments";

interface SegmentFilterProps {
  isInitialOpen?: boolean;
  onApply: (value: BaseFilter | null) => void;
  param: BaseFilter;
}

export const SegmentFilter = ({
  isInitialOpen = false,
  onApply,
  param,
}: SegmentFilterProps) => {
  const { segments, isLoading } = useSegments();
  const selectedSegment = useMemo(
    () => segments?.find(item => Number(item.id) === Number(param.value)),
    [segments, param.value],
  );

  const segmentItems = useMemo(
    () =>
      segments?.map(segment => ({
        label: segment.title,
        value: segment.id.toString(),
      })),
    [segments],
  );

  const FilterChip = useMemo(
    () => (
      <Filter.Chip
        renderSelectedValue={() =>
          renderSingleSelectFilterSelectedText({ param, items: segmentItems })
        }
      >
        {t([localeNamespace, "singular_title"])}
      </Filter.Chip>
    ),
    [param, segmentItems],
  );

  return (
    <Filter
      onApply={value => {
        onApply?.(value);
      }}
    >
      <SegmentTooltip selectedSegment={selectedSegment}>
        {FilterChip}
      </SegmentTooltip>
      <Filter.Popover
        title={t([localeNamespace, "title"])}
        isInitialOpen={isInitialOpen}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <SingleSelectFilter
            options={segmentItems || []}
            legend={t([localeNamespace, "singular_legend"])}
            param={param}
            hasSearch
            searchProps={{
              placeholder: t([localeNamespace, "search_placeholder"]),
            }}
          />
        )}
      </Filter.Popover>
    </Filter>
  );
};
